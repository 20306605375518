import axios from './axios_client'

const url = process.env.REACT_APP_API_PATH

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    CUSTOMER_LOGIN_WITH_PASSWORD: (keyword) => {
        //console.log("customer login api: ", keyword)
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/login_password`,
            data:keyword
        })
    },
    CREDIT_CHECK: () => {
        //console.log("credit check api: ")
        return axios({
            //headers: {},
            method: 'GET',
            url: `${url}/customer/credit_check`,
        })
    },
    DEPOSIT_CUSTOMER: () => {
        //console.log("deposit customer api: ")
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/deposit`
        })
    },
    HISTORY_CUSTOMER: () => {
        //console.log("deposit customer api: ")
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/history`
        })
    },
    WITHDRAW_CUSTOMER: (keyword) => {
        //console.log("deposit customer api: ")
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/withdraw`,
            data: keyword
        })
    },
    REFUND_INQUIRY: () => {
        //console.log("refund inquiry api: ")
        return axios({
            //headers: {},
            method: 'GET',
            url: `${url}/customer/refund_inquiry`,
        })
    },
    REFUND_CONFIRM: (request) => {
        //console.log("refund confirm api: ", request)
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/request_refund`,
            data: request
        })
    },
    GET_CUSTOMER_GAME_URL: (request) =>{
        //console.log("get customer api: ", request)
        return axios({
            //headers: {},
            method: 'GET',
            url: `${url}/customer/game_url`,
            data: request
        })
    },
    DEPOSIT_QR_API: (request) =>{
        //console.log("get deposit qr api: ", request)
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/qr_with_amount`,
            data: request
        })
    },
    GET_LINE_ID: (request) => {
        return axios({
            method: 'POST',
            url: `${url}/customer/welcome`,
            data: request
        })
    },
    REDIRECT_LOGIN: (data) => {
        //console.log("redirect login api: ", data)
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/login_with_token`,
            data: data
        })
    },
    GET_THAI_BANK_API: () => {
        //console.log("request thai bank api: ")
        return axios({
            method: 'GET',
            url: `${url}/customer/thai_banks`,
        })
    },
    CREATE_CUSTOMER: (data) => {
        ////console.log("update customer api: ", data)
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/online_register`,
            data: data
        })
    },
    ADD_TRUE_WALLET_ACCOUNT: (data) => {
        //console.log("deposit customer api: ")
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/add_true_wallet`,
            data: data
        })
    },
    GENERATE_DEPOSIT_FLOAT: (data) =>{
        //console.log("deposit customer api: ")
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/transfer_with_amount`,
            data: data
        })
    },
    GET_NEWS: (data) =>{
        //console.log("deposit customer api: ")
        return axios({
            //headers: {},
            method: 'POST',
            url: `${url}/customer/news`,
            data: data
        })
    },
    PRE_REGISTER: (data) => {
        return axios({
            method: 'POST',
            url: `${url}/customer/pre_register`,
            data: data
        })
    },
    GET_WITHDRAW_STATUS: () => {
        return axios({
            method: 'GET',
            url: `${url}/customer/inquiry_withdraw`,
        })
    },
    PROSPECT: (data) => {
        return axios({
            method: 'POST',
            url: `${url}/customer/prospect`,
            data: data
        })
    },
    CHECK_LUCKY_DRAW: () => {
        return axios({
            method: 'GET',
            url: `${url}/luckydraw/check`
        })
    },
    LUCKY_DRAW: (data) =>{
        return axios({
            method: 'POST',
            url: `${url}/luckydraw/draw`,
            data: data
        })
    },
    DEPOSIT_WITH_QR: (data) =>{
        return axios({
            method: 'POST',
            url: `${url}/customer/deposit_with_qr`,
            data: data
        })
    },
}